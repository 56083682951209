.navbar_container{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
    font-size: 14.5px;
    flex-wrap: wrap;
}

.brand{
    max-width: 210px;
}

.brand img{
    width: 100%;
}

.thin_line{
    border-bottom: 1px solid lightgrey;
}

a{
    text-decoration: none;
    color: black;
}

ul{
    list-style-type: none;
}

.bar{
    display: none;
}

.navlinks{
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
}

.sign_in{
    background-color: #ededed;
    padding: 8px 10px;
    border-radius: 4px;
}

.start{
    background-color: orangered;
    color: white;
    padding: 8px 10px;
    border-radius: 4px;
}

@media screen and (max-width: 600px) {
    .bar{
        display: block;
        width: 25px;
        height: 25px;
        font-size: 25px;
    }

    .navbar_container{
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }

    .links{
        width: 100%;
        margin: 25px 0;
    }

    .navlinks a{
        border-bottom: 1.5px solid lightgrey;
        width: 100%;
        padding: 15px 0;
        text-align: center;
    }

    .brand{
        max-width: 180px;
    }

    .navlinks{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: flex-start;
        gap: 0;
    }

    .sign_in, .start{
        width: 100px;
        margin: auto;
    }

    /* .thin_line{
        display: none;
    } */
}