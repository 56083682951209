@font-face {
  font-family: inter-regular;
  src: url('../Inter/static/Inter-Regular.ttf');
}

@font-face {
  font-family: inter-bold;
  src: url('../Inter/static/Inter-Bold.ttf');
}


h1, h2{
  font-family: inter-bold;
  margin: 8px 0;
} 

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 15px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: inter-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
